import React from 'react';
import classNames from 'classnames';
import * as classes from './Tag.module.scss';

export interface TagProps {
    label: string;
    className?: string;
}

const Tag: React.FC<TagProps> = ({
    label,
    className
}) => {
    const containerStyles = classNames({
        [classes['container']]: true,
        [className]: className
    });

    return (
        <div className={containerStyles}>
            { label }
        </div>
    );
}

export { Tag }